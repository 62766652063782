<template>
  <div class="ui grid container-5">
    <div class="sixteen wide column">
      <div class="instadetails">
        <div class="headerdetails">
          <h5>QUICK STEPS TO</h5>
          <h1>PAY VIA QR PH<span> VIA INSTAPAY</span></h1>
          <p>Look for the QR PH logo and enjoy cashless shopping experience</p>
        </div>

        <div class="stepsdetails">
          <div class="step">
            <div class="item">Step 1 <br></div>
            <div class="item">2</div>
            <div class="item">3</div>
          </div>
          <div class="details">
            <div class="item">Open your TayoCash app and tap SCAN QR icon</div>
            <div class="item">Scan or upload merchant's QR PH Code and/or enter amount</div>
            <div class="item">Review and confirm transaction details</div>
          </div>
          <div class="buttons">
            <router-link class="yellow" @click.native="removehash" :to="{ path: '/faq/services', hash: '#payqr'}" replace>
              LEARN MORE
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <img src="@/assets/wn5.png">
  </div>
</template>

<script>
export default {
  name: 'content5',
  methods: {
    removehash() {
      this.$nextTick(() => {
        setTimeout(function() {
          window.location.hash = '_'
          history.replaceState(null, null, ' ');
        }, 50)
      })
    },
  }
}
</script>

<style scoped lang='scss'></style>
